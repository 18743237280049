import { useMediaQuery } from '@mantine/hooks'
import { IKlass } from 'features/klass/types'
import _ from 'lodash'
import Link from 'next/link'
import sendNaverConversion from 'utils/sendNaverConversion'

interface ITrackCardProps {
  data: IKlass
}

const TrackCard = ({ data }: ITrackCardProps) => {
  const isTablet = useMediaQuery('(max-width: 1024px)')

  return (
    <div className="space-y-3 py-5 lg:space-y-4 lg:py-0">
      <Link href={`/onboarding/${data.id}`}>
        <a className="block space-y-4" onClick={() => sendNaverConversion('custom004')}>
          <img
            src={isTablet ? data.imageM : data.ogImage}
            className="w-full rounded-lg md:rounded-none lg:rounded-xl"
          />
          <div className="space-y-2">
            {!_.isEmpty(data.tags) && (
              <div className="flex items-center gap-[6px]">
                {data.tags?.map(v => (
                  <div
                    key={v.id}
                    className="rounded bg-trueGray-100 px-1 py-[2px] text-xs text-trueGray-500 lg:px-2 lg:py-1 lg:text-sm">
                    {v.name}
                  </div>
                ))}
              </div>
            )}
            <h4 className="text-sm font-semibold text-gray-800 lg:text-lg">{data.title}</h4>
            <p className="text-xs text-trueGray-500 lg:text-base">{data.subtitle}</p>
            <div className="space-x-1">
              <span className="text-sm font-medium text-gray-800 lg:text-base">
                {(Math.round((data.price * 0.1) / 10) * 10).toLocaleString()}원
              </span>
              <span className="text-xs text-gray-400 line-through lg:text-sm">
                {data.price.toLocaleString()}원
              </span>
            </div>
            <div className="inline-block rounded bg-yellow-100 px-1.5 py-0.5 text-xs font-semibold text-yellow-450 lg:text-xs">
              수료 시 전액환급
            </div>
          </div>
        </a>
      </Link>
    </div>
  )
}

export default TrackCard
